import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'st-loader',
  imports: [CommonModule],
  template: `
    <div *ngIf="!center" [class]="['st-loader', class, getSize]">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div *ngIf="center" class="flex w-full my-5 items-center justify-center">
      <div [class]="['st-loader', class, getSize]">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  `,
  styles: [
    `
      .st-loader {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 64px;
          height: 64px;
          margin: 8px;
          border: 8px solid #9dd9f7;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: #9dd9f7 transparent transparent transparent;

          &:nth-child(1) {
            animation-delay: -0.45s;
          }
          &:nth-child(2) {
            animation-delay: -0.3s;
          }

          &:nth-child(3) {
            animation-delay: -0.15s;
          }
        }

        &--size-sm {
          width: 60px;
          height: 60px;
          div {
            width: 48px;
            height: 48px;
            margin: 6px;
            border-width: 6px;
          }
        }
      }

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class StLoaderComponent {
  @Input() class: string = '';
  @Input() size: string = 'md';
  @Input() center: boolean = false;

  get getSize(): string {
    return `st-loader--size-${this.size}`;
  }
}
