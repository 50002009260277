import { NgModule } from '@angular/core';

import { LandingComponent } from './landing.component';
import { StComponentsModule } from '../components/st-components.module';
import { HeroesComponent } from './heros/heroes.component';
import { AuthModule } from '../auth/auth.module';
import { LanguagesComponent } from './languages/languages.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { WhySpeaktokComponent } from './why-speaktok/why-speaktok.component';
import { VoiceOfOurLearnersComponent } from './voice-of-our-learners/voice-of-our-learners.component';
import { TrialLessonComponent } from './trial-lesson/trial-lesson.component';
import { BillingPlansComponent } from './billing-plans/billing-plans.component';

import { FooterMenuComponent } from '../components/footer-menu/footer-menu.component';

@NgModule({
  declarations: [
    LandingComponent,
    HeroesComponent,
    LanguagesComponent,
    HowItWorksComponent,
    WhySpeaktokComponent,
    TrialLessonComponent,
    BillingPlansComponent,
    VoiceOfOurLearnersComponent,
  ],
  imports: [StComponentsModule, AuthModule, FooterMenuComponent],
  exports: [LandingComponent],
})
export class LandingModule {}
