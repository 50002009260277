import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationPathsService } from '../../navigation.paths.service';
import { Observable } from 'rxjs';
import { BillingPlanModel } from './model/billing-plan.model';

@Injectable({ providedIn: 'root' })
export class BillingPlansService {
  constructor(
    private http: HttpClient,
    private navigationPaths: NavigationPathsService
  ) {}

  getBillingPlans(): Observable<BillingPlanModel[]> {
    return this.http.get<BillingPlanModel[]>(
      this.navigationPaths.publicBillingPlans()
    );
  }
}
