import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-flag-icon',
  templateUrl: './st-flag-icon.component.html',
  styleUrls: ['./st-flag-icon.component.scss'],
})
export class StFlagIconComponent {
  @Input() type: 'none' | 'en' | 'de' | 'es' | 'fr' = 'none';
  @Input() size: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' = 'l';
  @Input() mode: 'icon' | 'button' = 'icon';
}
