<main class="container mx-auto">
  <st-heroes
    heroName="Thomas"
    heroDescription="learning English for 10 months" />
  <st-languages />
  <st-how-it-works />
  <st-why-speaktok />
  <!--  <st-trial-lesson></st-trial-lesson>-->
  <st-voice-of-our-learners />
  <st-billing-plans></st-billing-plans>
</main>
<footer class="container mx-auto">
  <st-footer-menu />
</footer>
