import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthWallService } from '../../auth/auth-wall/auth-wall.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'st-heroes',
  template: `
    <div class="heroes">
      <div class="heroes__menu">
        <div
          *ngIf="isLogged"
          class="font-book cursor-pointer"
          (click)="goToDashboard()">
          Dashboard
        </div>
        <st-link
          *ngIf="!isLogged"
          text="Log in"
          textSize="16px"
          textStyle="book"
          [textUnderline]="false"
          (linkClicked)="openLoginPage()"></st-link>
      </div>

      <div class="landing-logo">
        <a href="/" title="SpeakTok">
          <svg-icon
            src="assets/img/logo/Speaktok.svg"
            [svgStyle]="{ 'width.px': 130 }" />
        </a>
      </div>

      <div class="heroes__header md:w-9/12 lg:w-8/12">
        Learn a language with: passion, fun and engagement.
      </div>

      <div
        class="heroes__subheader font-book my-5 md:my-6 xl:my-10 text-lg md:text-2xl">
        Feel them all with the right teacher
      </div>

      <div class="my-6 md:my-14">
        <st-button
          text="Match me"
          [iconActive]="true"
          width="159px"
          routerLinkPath="/match"></st-button>
      </div>

      <div class="heroes__description sm:w-5/12">
        <div class="heroes__description-name font-book">— {{ heroName }}</div>
        <div class="heroes__description-experience">{{ heroDescription }}</div>
      </div>
    </div>
  `,
  styleUrls: ['./heroes.component.scss'],
})
export class HeroesComponent {
  @Input() heroName = '';
  @Input() heroDescription = '';

  constructor(
    private router: Router,
    private authWallService: AuthWallService,
    private authService: AuthService
  ) {}

  get isLogged(): boolean {
    return this.authService.loggedInUser.value !== null;
  }

  redirectToLogin() {
    this.router.navigate(['/auth']);
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  openLoginPage() {
    this.authWallService.activated.next({
      authWallActive: true,
      loginMode: true,
    });
  }
}
