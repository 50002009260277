import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { DirectivesModule } from 'src/app/directives/directives/directives.module';

@Component({
  standalone: true,
  imports: [CommonModule, SvgIconComponent, DirectivesModule],
  selector: 'st-footer-menu',
  template: `
    <div class="footer-menu">
      <div
        class="px-8 lg:w-8/12 mx-auto grid gap-10 grid-cols-2 xs:grid-cols-1">
        <section>
          <div class="footer-menu__header">{{ currentYear }} Speaktok</div>
          <div class="footer-menu__content">
            <a stSeoLink="faq" class="footer-menu__link">FAQ</a>
            <a stSeoLink="teachers-regulations" class="footer-menu__link"
              >Teacher's Regulations</a
            >
            <a stSeoLink="customer-regulations" class="footer-menu__link"
              >Customer Regulations</a
            >
            <a stSeoLink="privacy-policy" class="footer-menu__link"
              >Privacy Policy</a
            >
          </div>
        </section>

        <section>
          <div class="footer-menu__header">Questions?</div>
          <div class="footer-menu__content">
            <div class="mb-3">Want to become a teacher?</div>
            Contact us: <a href="mailto:hi@speaktok.com">hi@speaktok.com</a>
          </div>
        </section>
      </div>
    </div>
  `,
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent {
  get currentYear(): number {
    return new Date().getFullYear();
  }
}
