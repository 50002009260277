<div class="row">
  <div class="col-11 col-xl-11">
    <p>Buy your lessons</p>
  </div>
  <div class="col-1 col-xl-1">
    <button
      type="button"
      class="btn-close float-end"
      aria-label="Close"
      routerLink="/"></button>
  </div>
</div>
