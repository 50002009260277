import { Component, Input } from '@angular/core';

@Component({
  selector: 'st-article',
  templateUrl: './st-article.component.html',
  styleUrls: ['st-article.component.scss'],
})
export class StArticleComponent {
  @Input() articleNumber: string = '';
  @Input() headerText: string = '';
  @Input() marginLeft: string = '0px';
  @Input() paddingLeft: string = '0px';
  @Input() paddingTop: string = '0px';
}
