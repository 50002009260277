import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-image',
  template: `
    <img
      [src]="src"
      [alt]="alt ?? ''"
      [style]="getStyle()"
      [srcset]="getSrcSet()"
      [ngClass]="[class]"
      [loading]="loadingType" />
  `,
  styles: [``],
})
export class ImageComponent {
  @Input() src: string;
  @Input() alt: string;
  @Input() width: string;
  @Input() height: string;
  @Input() size: string;
  @Input() class: string = '';
  @Input() x2: boolean;

  loadingType: string = 'eager';

  constructor() {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      this.loadingType = 'lazy';
    }
  }

  getWidth(): string {
    return this.size ?? this.width ?? '100px';
  }

  getHeight(): string {
    return this.size ?? this.height ?? '100px';
  }

  getStyle(): object {
    return {
      width: this.getWidth(),
      height: this.getHeight(),
    };
  }

  getSrcSet(): string {
    if (!this.x2) {
      return '';
    }

    const [name, extension] = this.src.split('.');
    const x2Src = `${name}@2x.${extension}`;

    return `${this.src} 1x, ${x2Src} 2x`;
  }
}
