import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';
import {AuthGuard} from './auth/auth.guard';

import {LandingComponent} from './landing/landing.component';
import {ConfirmRegistrationComponent} from './confirm-registration/confirm-registration.component';
import {ChangePasswordProxyComponent} from './change-password/change-password-proxy.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {InternalServerErrorComponent} from './internal-server-error/internal-server-error.component';

const appRoutes: Routes = [
  { path: '', component: LandingComponent, title: 'SpeakTok' },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./profile/profile.component').then(mod => mod.ProfileComponent),
    title: 'Profile',
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        mod => mod.DashboardComponent
      ),
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },

  {
    path: 'meeting-room/:id',
    loadComponent: () =>
      import('./meeting-room/meeting-room.component').then(
        mod => mod.MeetingRoomComponent
      ),
    canActivate: [AuthGuard],
    title: 'Meeting Room',
  },
  {
    path: 'payments/retry',
    loadComponent: () =>
      import('./retry-payment/retry-payment.component').then(
        mod => mod.RetryPaymentComponent
      ),
    title: 'Retry payment',
  },
  {
    path: 'confirm-registration',
    component: ConfirmRegistrationComponent,
    title: 'Confirm registration',
  },
  {
    path: 'confirm-registration/teacher',
    component: ConfirmRegistrationComponent,
    title: 'Confirm registration',
  },
  {
    path: 'auth/change-password',
    component: ChangePasswordProxyComponent,
    title: 'Change password',
  },
  {
    path: 'checkout',
    loadComponent: () =>
      import('./checkout/checkout.component').then(
        mod => mod.CheckoutComponent
      ),
    title: 'Checkout',
  },
  {
    path: 'checkout-status',
    loadComponent: () =>
      import(
        './checkout/checkout-status-view/checkout-status-view.component'
      ).then(mod => mod.CheckoutStatusViewComponent),
    title: 'Checkout',
  },

  {
    path: 'match',
    loadComponent: () =>
      import('./match/match.component').then(mod => mod.MatchComponent),
    title: 'Matching with a teacher',
  },
  {
    path: 'match-results',
    loadComponent: () =>
      import('./match/results/match-results.component').then(
        mod => mod.MatchResultsComponent
      ),
    title: 'Match Results',
  },

  {
    path: 'faq',
    loadComponent: () =>
      import('./faq/faq.component').then(mod => mod.FaqComponent),
    title: 'FAQ',
  },

  {
    path: 'teacher/:id',
    loadComponent: () =>
      import('./teacher-details/teacher-details.component').then(
        mod => mod.TeacherDetailsComponent
      ),
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    title: 'Page Not Found',
  },
  {
    path: '500',
    component: InternalServerErrorComponent,
    title: 'Internal Server Error',
  },
  {
    path: ':id',
    loadComponent: () =>
      import('./cms-content/cms-content.component').then(
        mod => mod.CmsContentComponent
      ),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
