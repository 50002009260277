import { Component, OnInit } from '@angular/core';
import { LanguageModel } from '../../match/language/model/language.model';
import { Router } from '@angular/router';
import { LanguageService } from '../../match/language/language.service';

@Component({
  selector: 'st-languages',
  template: `
    <div class="choose-language">
      <div class="header font-book">
        Which language do you want to start learning?
      </div>
      <div class="languages-to-choose">
        <div
          class="choose-language-container cursor-pointer"
          (click)="onLanguageChose(language)"
          *ngFor="let language of languages">
          <figure>
            <img src="{{ language.largeIcon }}" alt="{{ language.name }}" />
          </figure>
          <button (click)="onLanguageChose(language)">
            {{ language.name }}
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  languages: LanguageModel[];

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    try {
      this.languages = await this.languageService.findAll();
    } catch (error) {
      console.error('Error fetching languages', error);
    }
  }

  onLanguageChose(language: LanguageModel) {
    this.router.navigate(['match', { language: language.name }]);
  }
}
