import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthWallService {
  activated = new Subject<{
    authWallActive: boolean;
    activationToken?: string;
    email?: string;
    teacher?: boolean;
    registrationMode?: boolean;
    loginMode?: boolean;
    changePasswordToken?: string;
    setNewPasswordMode?: boolean;
  }>();
}
