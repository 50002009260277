import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StIconComponent } from '../st-icon/st-icon.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { MultipleChooseComponent } from '../st-multiple-choose/multiple-choose.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    StIconComponent,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    MultipleChooseComponent,
  ],
  selector: 'st-search',
  template: `
    <div class="st-search">
      <div class="st-search__input flex">
        <div class="flex items-center ml-2">
          <svg-icon
            src="assets/svg/ico-search.svg"
            class="mx-auto"
            [svgStyle]="{ 'width.px': 22 }" />
        </div>
        <input
          type="text"
          class="flex-grow"
          [attr.disabled]="isDisabled ? 'disabled' : null"
          [placeholder]="placeholder"
          [formControl]="searchControl"
          (keyup.enter)="onChoseAccepted()"
          (click)="onChoseAccepted()" />
        <div
          *ngIf="addIcon"
          class="st-search__add-icon flex items-center justify-center"
          (click)="onChoseAccepted()">
          <svg-icon
            src="assets/svg/ico-plus.svg"
            class="mx-auto"
            [svgStyle]="{ 'width.px': 22 }" />
        </div>
        <ng-content select="[suffix]"></ng-content>
      </div>

      <div *ngIf="helpText" class="st-search-help-text">
        <span>{{ helpText }}</span>
      </div>

      <div
        *ngIf="searchResult"
        [hidden]="isHidden"
        class="st-search-chose-items">
        <span class="st-search-chose-item" (click)="addToTheSelected()">
          {{ searchResult.value }}
        </span>
      </div>

      <st-multiple-choose
        *ngIf="selectedItems?.length > 0"
        [itemsDictionary]="selectedItems"
        [removable]="true"
        (itemDeleted)="itemDeleted.emit($event)" />
    </div>
  `,
  styles: [
    `
      @import 'variables';

      .st-search {
        &__input {
          @apply w-full relative p-2;
          min-height: 42px;

          box-sizing: border-box;
          background: $white;
          border: 1px solid #d6d8d5;
          box-shadow:
            0 0 1px rgba(90, 89, 82, 0.15),
            0 1px 4px rgba(90, 89, 82, 0.15);
          border-radius: 12px;

          input {
            @apply mx-4;
            padding-top: 4px;
          }
        }

        &__add-icon {
          @apply -my-1 -mr-1;
          width: 38px;
          height: 38px;
          background: $base-gray-10;
          border: 1px solid $base-gray-10;
          border-radius: 8px;
          cursor: pointer;

          transition: all 0.3s ease;

          &:hover {
            background: $base-gray-20;
          }
        }
      }

      .st-search-help-text span {
        font-size: 13px;
        line-height: 20px;
        color: #979d96;
        margin-left: 17px;
        margin-top: 2px;
      }

      .st-search-chose-items {
        position: absolute;
        width: 100%;
        height: 42px;
        display: flex;
        flex-flow: column nowrap;
        background: $white;
        border: 1px solid #d6d8d5;
        box-shadow:
          0 0 1px rgba(90, 89, 82, 0.15),
          0 1px 4px rgba(90, 89, 82, 0.15);
        border-radius: 12px;
        cursor: pointer;
      }

      .st-search-chose-items:hover {
        background-color: $base-gray-10;
        border-color: $primary;
      }

      .st-search-chose-item {
        height: 42px;
        padding: 9px 17px;
      }

      .st-search-chose-item:last-child {
        border-bottom: none;
      }

      .st-icons-delete {
        width: 24px;
        height: 24px;
        position: absolute;
        margin-left: 6px;
        margin-right: 6px;
        background-image: url('/assets/components/icons/delete2.jpg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    `,
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  private searchControlSub: Subscription;

  @Input() placeholder = 'Find';
  @Input() helpText = '';
  @Input() availableItems: { value: string }[] = [];
  @Input() searchControl = new FormControl('');
  @Input() selectedItems: { value: string }[] = [];
  @Input() maxNumberOfSearchItems = 3;
  @Input() addIcon: boolean = false;

  @Output() itemSelected = new EventEmitter<{ value: string }>();
  @Output() itemDeleted = new EventEmitter<{ value: string }>();

  isHidden = true;

  searchResult: { value: string };

  ngOnInit(): void {
    this.searchControlSub = this.searchControl.valueChanges.subscribe(value => {
      if (value && value.length >= this.maxNumberOfSearchItems) {
        this.isHidden = false;
        this.searchResult = this.availableItems.find(
          availableItem =>
            availableItem.value?.match(value) &&
            !this.selectedItems.find(
              selectedItem => selectedItem.value === availableItem.value
            )
        );
      } else {
        this.isHidden = true;
        this.searchResult = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.searchControlSub.unsubscribe();
  }

  get isDisabled(): boolean {
    const selectedLength = this.selectedItems?.length ?? 0;
    return selectedLength >= this.maxNumberOfSearchItems;
  }

  addToTheSelected() {
    if (
      this.selectedItems.find(item => item.value === this.searchResult.value)
    ) {
      this.isHidden = true;
      this.searchControl.setValue('');
      return;
    }
    this.itemSelected.emit(Object.assign({}, this.searchResult));
    this.selectedItems.push(Object.assign({}, this.searchResult));
    this.isHidden = true;
    this.searchControl.setValue('');
  }

  deleteFromSelected(deletedItem: { value: string }) {
    this.selectedItems = this.selectedItems.filter(item => item != deletedItem);
    this.isHidden = true;
    this.searchControl.setValue('');
    this.itemDeleted.emit(deletedItem);
  }

  onChoseAccepted() {
    if (
      this.searchControl.value &&
      this.searchControl.value.length >= 3 &&
      this.selectedItems.length < this.maxNumberOfSearchItems
    ) {
      this.itemSelected.emit({ value: this.searchControl.value });
      this.selectedItems.push({ value: this.searchControl.value });
      this.isHidden = true;
      this.searchControl.setValue('');

      // set focus to the input
      const input = document.querySelector('.st-search__input input');
      if (input instanceof HTMLElement) {
        input.focus();
      }
    }
  }
}
