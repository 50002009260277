<div class="links-editor">
  <h5 class="font-book">{{ title }}</h5>
  <span>{{ description }}</span>
  <div class="links">
    <div
      class="link-row input-container"
      *ngFor="let link of linksFormArray.controls; let i = index">
      <st-input
        [value]="link.value"
        [inputControl]="toFormControl(link)"
        width="600px"
        (focusout)="addLink(i, link.value)"></st-input>
      <st-icon
        type="delete"
        mode="button"
        color="grey"
        size="m"
        (click)="deleteLink(i)"></st-icon>
    </div>
    <div class="link-row button-container" *ngIf="previousLinkAdded">
      <st-link
        text="{{ addLinkText }}"
        [textUnderline]="false"
        icon="plus-circle"
        iconColor="orange"
        (linkClicked)="addNextLink()"></st-link>
    </div>
  </div>
</div>
