import { ApiErrorsService } from './../../dictionaries/api-errors/api-errors.service';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'st-alert',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  template: `
    <div
      class="st-alert"
      [ngClass]="['st-alert--v-' + getVariant, 'st-alert--s-' + size, class]">
      <div class="st-alert__icon self-start">
        <svg-icon
          [src]="'assets/svg/' + iconName + '.svg'"
          [svgStyle]="{ 'width.px': size === 'md' ? 24 : 20 }" />
      </div>
      <div class="st-alert__message flex-grow">
        <ng-content></ng-content>{{ message || errorMessage }}
      </div>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      .st-alert {
        @apply flex items-center mb-6 px-5;
        border-radius: 12px;

        &__message {
          padding-top: 13px;
          padding-bottom: 14px;
        }

        &__icon {
          @apply pr-3 pt-3;
        }

        &--v-info {
          background-color: #eef5ff;
          color: #1e3a8a;
        }

        &--v-info-gray {
          background-color: $base-gray-10;
          color: $base-gray-90;
        }

        &--v-success {
          background-color: #f0fff5;
          color: #0d3e1e;
        }

        &--v-warning {
          background-color: rgba($alert-100, 0.05);
          color: $alert-100;
        }

        &--v-error {
          background-color: #fff5f5;
          color: #7f1d1d;
        }

        &--s-sm {
          @apply mb-4 px-3;
          .st-alert {
            &__message {
              @apply text-sm;
              padding-top: 8px;
              padding-bottom: 7px;
            }
            &__icon {
              @apply pr-2;
              padding-top: 5px;
            }
          }
        }

        &__icon {
          opacity: 0.8;
        }
      }
    `,
  ],
})
export class AlertComponent {
  @Input() class: string = '';

  @Input() variant: 'info' | 'info-gray' | 'success' | 'warning' | 'error' =
    'info';
  @Input() size: 'sm' | 'md' = 'md';
  @Input() message: string;
  @Input() apiError: HttpErrorResponse;

  constructor(private apiErrorsService: ApiErrorsService) {}

  get iconName(): string {
    switch (this.variant) {
      case 'info':
      case 'info-gray':
        return 'ico-info';
      case 'success':
        return 'ico-check-circle';
      case 'warning':
        return 'ico-warning';
      case 'error':
        return 'ico-error';
    }
  }

  get getVariant(): string {
    return this.apiError ? 'error' : this.variant;
  }

  get errorMessage(): string {
    if (this.apiError) {
      const { error, message } = this.apiError;

      return this.apiErrorsService.getMessage(error?.title || message);
    }
    return this.message;
  }
}
