import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  exhaustMap,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthService, Token, TokenType } from './auth.service';
import { UploadType } from '../components/st-file-upload/st-file-upload.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.loggedInUser.pipe(
      take(1),
      exhaustMap(user => {
        if (!user) {
          return next.handle(req.clone());
        }
        if (req.url.includes('/refresh-token')) {
          return next.handle(
            req.clone({
              body: {
                timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
              headers: new HttpHeaders()
                .set('X-JWT-TOKEN', user.token)
                .set('X-JWT-REFRESH-TOKEN', user.refreshToken),
            })
          );
        }
        if (req.url.includes('/files')) {
          return next
            .handle(
              req.clone({
                headers: new HttpHeaders()
                  .set('Authorization', 'Bearer ' + user.token)
                  .set('ST-UPLOAD-TYPE', UploadType.PROFILE_IMAGE),
              })
            )
            .pipe(
              catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                  return this.handle401Error(req, next);
                } else {
                  return throwError(err);
                }
              })
            );
        }
        return next
          .handle(
            req.clone({
              headers: new HttpHeaders().set(
                'Authorization',
                'Bearer ' + user.token
              ),
            })
          )
          .pipe(
            catchError(err => {
              if (err instanceof HttpErrorResponse && err.status === 401) {
                return this.handle401Error(req, next);
              } else {
                return throwError(err);
              }
            })
          );
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((tokens: Token[]) => {
        return next.handle(
          request.clone({
            headers: new HttpHeaders()
              .set(
                'Authorization',
                'Bearer ' +
                  tokens.find(token => TokenType.JWT_TOKEN === token.type)
                    ?.value
              )
              .set(
                'ST-UPLOAD-TYPE',
                request.headers.has('ST-UPLOAD-TYPE')
                  ? request.headers.get('ST-UPLOAD-TYPE')
                  : ''
              ),
          })
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
