import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkModel } from './link.model';
import { AbstractControl, UntypedFormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'st-links-editor',
  templateUrl: './st-links-editor.component.html',
  styleUrls: ['./st-links-editor.component.scss'],
})
export class StLinksEditorComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() links: LinkModel[] = [];
  @Input() inputPlaceholder = '';
  @Input() addLinkText = '';

  @Output() linkAdded = new EventEmitter<string>();
  @Output() linkDeleted = new EventEmitter<string>();

  linksFormArray: UntypedFormArray;
  previousLinkAdded = true;

  ngOnInit(): void {
    this.linksFormArray = new UntypedFormArray([]);
    this.links?.forEach(link =>
      this.linksFormArray.push(new FormControl(link.value))
    );
  }

  addNextLink(): void {
    this.linksFormArray.push(new FormControl(''));
    // this.previousLinkAdded = false;
  }

  toFormControl(abstractControl: AbstractControl): FormControl {
    return abstractControl as FormControl;
  }

  addLink(index: number, value: string) {
    if (
      this.linksFormArray.controls.map(c => c.value).filter(x => x === value)
        .length > 1
    ) {
      this.linksFormArray.removeAt(index, { emitEvent: false });
      return;
    }
    this.linksFormArray.at(index).setValue(value);
    this.linkAdded.emit(this.linksFormArray.at(index).value);
    // this.previousLinkAdded = true;
  }

  deleteLink(index: number) {
    this.linkDeleted.emit(this.linksFormArray.at(index).value);
    this.linksFormArray.removeAt(index);
  }
}
