<article
  [ngStyle]="{
    'margin-left': marginLeft ? marginLeft : '',
    'padding-left': paddingLeft ? paddingLeft : '',
    'padding-top': paddingTop ? paddingTop : ''
  }">
  <div class="header">
    <div class="article-number" *ngIf="articleNumber">
      <img
        src="assets/img/how-it-works/steps/step{{ articleNumber }}.svg"
        class="img-fluid step step1"
        width="48px"
        alt="" />
    </div>
    <span class="font-book">{{ headerText }}</span>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</article>
