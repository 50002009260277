<div class="auth-wall" [ngClass]="{ active: isActive }">
  <div *ngIf="isActive" class="container xs:mx-5">
    <div class="header">
      <svg-icon
        src="assets/img/logo/Speaktok.svg"
        class="mx-auto"
        [svgStyle]="{ 'width.px': 50 }" />

      <st-icon
        type="close"
        [size]="'xxs'"
        mode="button"
        (click)="onCloseClick()" />
    </div>

    <!-- login wall -->
    <div *ngIf="wallType === 'login'">
      <div class="welcome-message">
        <div class="welcome-message-header">Hey! Welcome back.</div>
        <div class="welcome-message-content">
          Improve your skills to reach your goals.
        </div>
      </div>

      <st-input
        [label]="'E-mail'"
        [name]="'username'"
        [inputControl]="usernameControl" />

      <st-input
        [name]="'password'"
        [type]="'password'"
        [label]="'Password'"
        [inputControl]="passwordControl"
        (enterPressed)="loginWithActivation()" />

      <div class="spacer">
        <div class="error-message" *ngIf="loginError">
          {{ loginErrorMessage }}
        </div>
      </div>

      <st-button
        text="Login"
        class="block mb-5"
        [block]="true"
        *ngIf="!registrationWall"
        (buttonClicked)="loginWithActivation()" />

      <st-button
        text="Close"
        class="block mb-5"
        [block]="true"
        [style]="'st-orange-grey'"
        (buttonClicked)="onCloseClick()" />

      <div class="login-footer">
        <div>
          <span>Forgot password?</span>
          <st-link
            text="Click here"
            textSize="13px"
            (linkClicked)="onShowChangePasswordWall()" />
        </div>
        <div>
          <span>Not a Speaktoker yet?</span>
          <st-link
            text="Match with your teacher now!"
            textSize="13px"
            (linkClicked)="onMatchTeacher()" />
        </div>
      </div>
    </div>

    <!-- registration wall -->
    <div *ngIf="wallType === 'registration'">
      <div class="welcome-message">
        <div class="welcome-message-header">Hi!</div>
        <div class="welcome-message-content">
          Eager to match ambitions with impactful upskilling opportunities? Join
          Speaktok!
        </div>
      </div>

      <st-input
        [label]="'E-mail'"
        [name]="'email'"
        [inputControl]="usernameControl" />

      <st-input
        [name]="'password'"
        [type]="'password'"
        [label]="'Password'"
        [inputControl]="passwordControl" />

      <st-password-checker [password]="passwordControl.value" />

      <div class="agreements">
        <div>
          <span>By signing up you agree to our</span>
          <st-link text="Terms of Service" textSize="13px" />
        </div>
        <div>
          <span>and</span>
          <st-link text="Privacy Policy" textSize="13px" />
        </div>
      </div>

      <st-button
        text="Agree & Join"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="signup()"></st-button>

      <st-button
        text="Close"
        [style]="'st-orange-grey'"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="onCloseClick()"></st-button>

      <div class="registration-footer">
        <span>Already a Speaktoker?</span>
        <st-link
          text="Login here"
          textSize="13px"
          (linkClicked)="onChangeToLoginMode()" />
      </div>
    </div>

    <!-- confirmation wall -->
    <div *ngIf="wallType === 'confirmation'">
      <div class="welcome-message">
        <div class="welcome-message-header student-registration-header">
          Almost there!
        </div>
        <!-- add username -->
        <div class="welcome-message-content">
          To complete the registration please confirm your email address.<br />You’ll
          get an email from us soon.
        </div>
      </div>
      <st-button
        text="Okay"
        [block]="true"
        (buttonClicked)="onCloseClick()"></st-button>
    </div>

    <!-- change password wall -->
    <div class="change-password-container" *ngIf="wallType === 'changePass'">
      <div class="welcome-message">
        <div class="welcome-message-header">Forgot your password?</div>
        <div class="welcome-message-content">
          No worries! Happens to the best of us. Enter your email, and we'll
          send a reset link.
        </div>
      </div>
      <div class="form-container">
        <st-input
          [label]="'E-mail'"
          [inputControl]="usernameControl"></st-input>
      </div>

      <div class="spacer reset-password-spacer"></div>

      <st-button
        text="Send Reset Link"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="onSendResetLink()"></st-button>

      <st-button
        text="Back"
        class="block mb-5"
        [block]="true"
        [style]="'st-orange-grey'"
        (buttonClicked)="onChangeToLoginMode()"></st-button>

      <div class="login-footer">
        <div>
          <span>Not a Speaktoker yet?</span>
          <st-link
            text="Match with your teacher now!"
            textSize="13px"
            (linkClicked)="onMatchTeacher()" />
        </div>
      </div>
    </div>

    <!-- change password confirmation wall -->
    <div
      *ngIf="wallType === 'changePassConfirmation'"
      class="change-password-container">
      <div class="welcome-message">
        <div class="welcome-message-header student-registration-header">
          Check your inbox!
        </div>
        <div class="welcome-message-content">
          Check your email at the moment and complete the change password
          process
        </div>
      </div>

      <st-button
        text="Close"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="onCloseClick()"></st-button>

      <div class="login-footer">
        <div>
          <span>Not a Speaktoker yet?</span>
          <st-link
            text="Match with your teacher now!"
            textSize="13px"
            (linkClicked)="onMatchTeacher()" />
        </div>
      </div>
    </div>

    <!-- set new password wall -->
    <div class="change-password-container" *ngIf="wallType === 'setNewPass'">
      <div class="welcome-message">
        <div class="welcome-message-header">Set a new password</div>
        <div class="welcome-message-content">
          Let's get you back to using our services.
        </div>
      </div>
      <div class="form-container">
        <st-input
          [type]="'password'"
          [label]="'Password'"
          [inputControl]="passwordControl" />

        <st-password-checker [password]="passwordControl.value" />
      </div>

      <div class="spacer reset-password-spacer"></div>

      <st-button
        text="Change password"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="onChangePassword()" />

      <st-button
        text="Back"
        [style]="'st-orange-grey'"
        class="block mb-5"
        [block]="true"
        (buttonClicked)="onChangeToLoginMode()" />
    </div>
  </div>
</div>
