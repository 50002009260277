import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InputService {
  constructor() {}

  getValidationErrorMessage(inputControl): string {
    if (inputControl.hasError('required')) {
      return 'Field is required';
    }

    if (inputControl.hasError('minlength')) {
      const { requiredLength } = inputControl.errors?.minlength;
      return `Field mast be at least ${requiredLength} characters long.`;
    }

    if (inputControl.hasError('email')) {
      return 'Invalid email address format';
    }

    if (
      inputControl.hasError('cardNumber') ||
      inputControl.hasError('expiryDate') ||
      inputControl.hasError('securityCode')
    ) {
      return 'Incorrect format';
    }

    return null;
  }
}
