import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { FooterMenuComponent } from '../footer-menu/footer-menu.component';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule, SvgIconComponent, FooterMenuComponent, RouterModule],
  selector: 'st-layout-default',
  template: `
    <div class="container mx-auto">
      <div class="mx-5 mt-10">
        <a
          href="/"
          routerLink="/"
          title="Speaktok"
          (click)="$event.preventDefault()">
          <svg-icon
            class="cursor-pointer"
            src="assets/img/logo/Speaktok.svg"
            [svgStyle]="{ 'width.px': 120 }" />
        </a>
      </div>

      <div class="lg:w-10/12 xl:w-9/12 mx-5 lg:mx-auto py-10">
        <ng-content />
      </div>

      <st-footer-menu />
    </div>
  `,
  styleUrls: ['./layout-default.component.scss'],
})
export class LayoutDefaultComponent {}
