<div
  [ngClass]="[
    'st-flag-icon',
    'st-flag-icon--mode-' + mode,
    'st-flag-icon--size-' + size
  ]">
  <div [ngClass]="'st-flag-icon__wrapper st-flag-icon-' + type">
    <svg
      *ngIf="type === 'en'"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_94_379)">
        <g clip-path="url(#clip0_94_379)">
          <rect
            x="-3"
            y="1.42856"
            width="38"
            height="27.1429"
            rx="4"
            fill="white" />
          <mask
            id="mask0_94_379"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="-3"
            y="1"
            width="38"
            height="28">
            <rect
              x="-3"
              y="1.42856"
              width="38"
              height="27.1429"
              rx="4"
              fill="white" />
          </mask>
          <g mask="url(#mask0_94_379)">
            <rect
              x="-3"
              y="1.42856"
              width="38"
              height="27.1429"
              fill="#0A17A7" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.05724 19.5238L-6.76422 28.172L-4.74048 31.1723L11.4762 20.234V30.3809H20.5238V20.234L36.7405 31.1723L38.7643 28.172L25.9428 19.5238H35V10.4762H25.9428L38.7643 1.82799L36.7405 -1.17233L20.5238 9.76596V-0.380962H11.4762V9.76596L-4.74047 -1.17233L-6.76422 1.828L6.05723 10.4762H-2.99998V19.5238H6.05724Z"
              fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.9018 -1.53436C40.0391 -1.32563 39.9812 -1.04511 39.7725 -0.907797L22.5838 10.4002C22.375 10.5375 22.0945 10.4796 21.9572 10.2709C21.8199 10.0622 21.8778 9.78164 22.0865 9.64432L39.2752 -1.66366C39.4839 -1.80097 39.7644 -1.74308 39.9018 -1.53436Z"
              fill="#DB1F35" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.9441 30.6569C40.0837 30.4497 40.029 30.1685 39.8218 30.0289L24.413 19.6429C24.2059 19.5033 23.9247 19.558 23.7851 19.7652C23.6454 19.9724 23.7002 20.2535 23.9073 20.3932L39.3161 30.7791C39.5233 30.9188 39.8045 30.864 39.9441 30.6569Z"
              fill="#DB1F35" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-8.58317 -1.09202C-8.44355 -1.2992 -8.1624 -1.35397 -7.95521 -1.21434L8.11745 9.61762C8.32464 9.75725 8.3794 10.0384 8.23977 10.2456C8.10014 10.4528 7.819 10.5075 7.61181 10.3679L-8.46086 -0.464059C-8.66804 -0.603688 -8.7228 -0.884836 -8.58317 -1.09202Z"
              fill="#DB1F35" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-8.58505 31.9571C-8.44696 32.1653 -8.16623 32.2221 -7.95801 32.0841L9.85791 20.2694C10.0661 20.1313 10.123 19.8506 9.98491 19.6423C9.84682 19.4341 9.56609 19.3773 9.35787 19.5153L-8.45805 31.33C-8.66627 31.4681 -8.72313 31.7489 -8.58505 31.9571Z"
              fill="#DB1F35" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-3 17.7143H13.2857V28.5714H18.7143V17.7143H35V12.2857H18.7143V1.42856H13.2857V12.2857H-3V17.7143Z"
              fill="#E6273E" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_94_379"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_379" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_379"
            result="effect2_dropShadow_94_379" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_379"
            result="shape" />
        </filter>
        <!-- <clipPath id="clip0_94_379">
          <rect x="4" y="3" width="24" height="24" rx="12" fill="white" />
        </clipPath> -->
      </defs>
    </svg>

    <svg
      *ngIf="type === 'de'"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_94_442)">
        <g clip-path="url(#clip0_94_442)">
          <rect
            x="-3"
            y="1.42856"
            width="38"
            height="27.1429"
            rx="4"
            fill="white" />
          <mask
            id="mask0_94_442"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="-3"
            y="1"
            width="38"
            height="28">
            <rect
              x="-3"
              y="1.42856"
              width="38"
              height="27.1429"
              rx="4"
              fill="white" />
          </mask>
          <g mask="url(#mask0_94_442)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-3 10.4762H35V1.42856H-3V10.4762Z"
              fill="#262626" />
            <g filter="url(#filter1_d_94_442)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-3 19.5238H35V10.4762H-3V19.5238Z"
                fill="#F01515" />
            </g>
            <g filter="url(#filter2_d_94_442)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-3 28.5714H35V19.5238H-3V28.5714Z"
                fill="#FFD521" />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_94_442"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_442" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_442"
            result="effect2_dropShadow_94_442" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_442"
            result="shape" />
        </filter>
        <filter
          id="filter1_d_94_442"
          x="-3"
          y="10.4762"
          width="38"
          height="9.04761"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_442" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_94_442"
            result="shape" />
        </filter>
        <filter
          id="filter2_d_94_442"
          x="-3"
          y="19.5238"
          width="38"
          height="9.04761"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_442" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_94_442"
            result="shape" />
        </filter>
        <!-- <clipPath id="clip0_94_442">
          <rect x="4" y="3" width="24" height="24" rx="12" fill="white" />
        </clipPath> -->
      </defs>
    </svg>

    <svg
      *ngIf="type === 'es'"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_94_420)">
        <g clip-path="url(#clip0_94_420)">
          <rect
            x="2"
            y="1.42856"
            width="38"
            height="27.1429"
            rx="4"
            fill="white" />
          <mask
            id="mask0_94_420"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="2"
            y="1"
            width="38"
            height="28">
            <rect
              x="2"
              y="1.42856"
              width="38"
              height="27.1429"
              rx="4"
              fill="white" />
          </mask>
          <g mask="url(#mask0_94_420)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 8.66665H40V1.42856H2V8.66665Z"
              fill="#DD172C" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 28.5714H40V21.3333H2V28.5714Z"
              fill="#DD172C" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 21.3333H40V8.66666H2V21.3333Z"
              fill="#FFD133" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9524 14.0952H13.7619V15H11.9524V14.0952Z"
              fill="#FFEDB1" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.231 14.0952H10.6737L10.6737 14.0952L10.9062 16.8846C10.9453 17.3536 11.3373 17.7143 11.8078 17.7143H12.0969C12.5674 17.7143 12.9594 17.3536 12.9985 16.8846L13.231 14.0952ZM10.6737 13.1905C10.1444 13.1905 9.72815 13.6429 9.77211 14.1704L10.0046 16.9598C10.0827 17.8976 10.8667 18.619 11.8078 18.619H12.0969C13.038 18.619 13.822 17.8976 13.9002 16.9598L14.1326 14.1704C14.1766 13.6429 13.7603 13.1905 13.231 13.1905H10.6737Z"
              fill="#A41517" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1429 15H13.7619V15.9048H12.8572L11.9524 17.7143L11.0476 15.9048H10.1429V15Z"
              fill="#A41517" />
            <rect
              x="7.42859"
              y="12.2857"
              width="1.80952"
              height="6.33333"
              rx="0.904762"
              fill="#A41517" />
            <rect
              x="14.6667"
              y="12.2857"
              width="1.80952"
              height="6.33333"
              rx="0.904762"
              fill="#A41517" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1429 11.9238C10.1429 11.1243 10.791 10.4762 11.5905 10.4762H12.3143C13.1138 10.4762 13.7619 11.1243 13.7619 11.9238C13.7619 12.1237 13.5999 12.2857 13.4 12.2857H10.5048C10.3049 12.2857 10.1429 12.1237 10.1429 11.9238Z"
              fill="#A41517" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_94_420"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_420" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_420"
            result="effect2_dropShadow_94_420" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_420"
            result="shape" />
        </filter>
        <!-- <clipPath id="clip0_94_420">
          <rect x="4" y="3" width="24" height="24" rx="12" fill="white" />
        </clipPath> -->
      </defs>
    </svg>

    <svg
      *ngIf="type === 'fr'"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_94_461)">
        <g clip-path="url(#clip0_94_461)">
          <rect x="4" y="3" width="24" height="24" rx="12" fill="white" />
          <mask
            id="mask0_94_461"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="26"
            height="24">
            <rect
              x="3.5"
              y="3.5"
              width="25"
              height="23"
              rx="3.5"
              fill="white"
              stroke="white" />
          </mask>
          <g mask="url(#mask0_94_461)">
            <rect x="20" y="3" width="9" height="24" fill="#F44653" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 27H12V3H3V27Z"
              fill="#1035BB" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_94_461"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_461" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.352941 0 0 0 0 0.34902 0 0 0 0 0.321569 0 0 0 0.15 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_461"
            result="effect2_dropShadow_94_461" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_461"
            result="shape" />
        </filter>
        <!-- <clipPath id="clip0_94_461">
          <rect x="4" y="3" width="24" height="24" rx="12" fill="white" />
        </clipPath> -->
      </defs>
    </svg>
  </div>
</div>
