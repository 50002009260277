import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavigationPathsService } from '../../navigation.paths.service';
import { lastValueFrom } from 'rxjs';
import { LanguageModel } from '@app/match/language/model/language.model';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(
    private http: HttpClient,
    private paths: NavigationPathsService
  ) {}

  async findAll(): Promise<LanguageModel[]> {
    const queryParams = new HttpParams().append('subsetType', 'TEACHING');
    const languages = await lastValueFrom(
      this.http.get<LanguageModel[]>(this.paths.languagesDict(), {
        params: queryParams,
      })
    );

    const orderByLocale = ['en', 'de'];

    languages.sort((a, b) => {
      const aIndex = orderByLocale.indexOf(a.locale);
      const bIndex = orderByLocale.indexOf(b.locale);

      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;

      return aIndex - bIndex;
    });

    return languages;
  }
}
