import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthWallService } from '../auth/auth-wall/auth-wall.service';

@Component({
  selector: 'st-change-password-proxy',
  templateUrl: './change-password-proxy.component.html',
})
export class ChangePasswordProxyComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authWallService: AuthWallService
  ) {}

  ngOnInit(): void {
    this.authWallService.activated.next({
      authWallActive: true,
      changePasswordToken: this.activatedRoute.snapshot.queryParams['token'],
      setNewPasswordMode: true,
    });
    this.router.navigate(['/']);
  }
}
