import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LayoutDefaultComponent } from '../components/layout-default/layout-default.component';

@Component({
  standalone: true,
  imports: [CommonModule, LayoutDefaultComponent],
  selector: 'st-internal-server-error',
  template: `
    <st-layout-default>
      <h1 class="internal-server-error__header font-semibold">500</h1>
      <div class="mb-8">
        <p class="mb-2">
          An internal server error occurred. Please try again later.
        </p>
        <p class="mb-2">
          Go to the <a href="/" class="underline">homepage</a>.
        </p>
      </div>
    </st-layout-default>
  `,
  styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent {}
