<div class="voice-of-our-learners">
  <h3
    class="text-5xl xs:text-4xl mt-14 mb-16 xs:mb-6 xs:mx-4 block text-center font-book">
    Voice of our learners
  </h3>

  <div class="voice-of-our-learners__items mx-auto">
    <div class="voice-of-our-learners__item voice-of-our-learners__item--t3">
      <div class="voice-of-our-learners__item-review">
        “I knew I needed professional help with my English when I realized my
        low level of fluency made it difficult to communicate with the CEO
        during business trips”
      </div>
      <div class="voice-of-our-learners__item-author">
        Thomas
        <small class="block">Marketing Manager</small>
        <small class="block">English learner for 8 months</small>
      </div>
      <div class="voice-of-our-learners__item-img">
        <st-image
          src="assets/img/voice-of-our-learners/t3-img.png"
          size="96px"
          alt="Thomas" />
      </div>
    </div>

    <div class="voice-of-our-learners__item voice-of-our-learners__item--t2">
      <div class="voice-of-our-learners__item-review">
        “I needed to pass my IELTS to get into my dream university. I couldn't
        find a regular class that didn’t clash with my schedule, so being able
        to make my own was a huge plus.”
      </div>
      <div class="voice-of-our-learners__item-author">
        Pavena
        <small class="block">University student</small>
        <small class="block">English learner for 11 months</small>
      </div>
      <div class="voice-of-our-learners__item-img">
        <st-image
          src="assets/img/voice-of-our-learners/t2-img.png"
          size="96px"
          alt="Pavena" />
      </div>
    </div>

    <div class="voice-of-our-learners__item voice-of-our-learners__item--t1">
      <div class="voice-of-our-learners__item-review">
        “What sets this app apart from the rest is the personalization. Finding
        the right teacher who vibed with me made all the difference. The
        feedback turned out to be really helpful too.”
      </div>
      <div class="voice-of-our-learners__item-author">
        Andrew
        <small class="block">Sales Department</small>
        <small class="block">Spanish learner for 2 years</small>
      </div>
      <div class="voice-of-our-learners__item-img">
        <st-image
          src="assets/img/voice-of-our-learners/t1-img.png"
          size="96px"
          alt="Andrew" />
      </div>
    </div>
  </div>
</div>
