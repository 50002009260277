import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LayoutDefaultComponent } from '../components/layout-default/layout-default.component';

@Component({
  standalone: true,
  imports: [CommonModule, LayoutDefaultComponent],
  selector: 'st-page-not-found',
  template: `
    <st-layout-default>
      <h1 class="page-not-found__header font-semibold">404</h1>
      <div class="mb-8">
        <p class="mb-2">The page you are looking for does not exist.</p>
        <p class="mb-2">
          Go to the <a href="/" class="underline">homepage</a>.
        </p>
      </div>
    </st-layout-default>
  `,
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {}
