import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorsService {
  constructor() {}

  getMessage(key: string): string {
    switch (key) {
      case 'there.is.another.active.payment':
        return 'There is another active payment. Please wait until it is completed.';

      default:
        return 'Something went wrong. Please try again later.';
    }
  }
}
