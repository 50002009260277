import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { StFileUploadService, UploadType } from './st-file-upload.service';
import { CommonModule } from '@angular/common';
import { StLinkComponent } from '../st-link/st-link.component';
import { StButtonComponent } from '../st-button/st-button.component';
import { AlertComponent } from '../alert/alert.component';

@Component({
  standalone: true,
  imports: [CommonModule, StLinkComponent, StButtonComponent, AlertComponent],
  selector: 'st-file-upload',
  template: `
    <st-alert *ngIf="uploadError" variant="error" class="mt-1">
      {{ uploadError }}
    </st-alert>
    <div class="st-file-upload">
      <input class="hidden-file-input" type="file" #fileInput />

      <st-link
        *ngIf="type === 'link'"
        text="{{ text }}"
        (linkClicked)="onAddPhoto()" />

      <st-button
        *ngIf="type === 'button'"
        text="{{ text }}"
        (buttonClicked)="onAddPhoto()" />

      <span
        *ngIf="helpText"
        class="md:ml-4 mt-1 md:mt-0 text-gray-400 text-sm md:text-base">
        {{ helpText }}
      </span>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      .st-file-upload {
        @apply flex flex-col md:flex-row;

        st-button {
          margin-top: -11px;
        }

        .hidden-file-input {
          display: none;
        }

        .help-text {
          margin-left: 20px;
          color: #979d96;
        }
      }
    `,
  ],
})
export class StFileUploadComponent {
  @Output() profileImageUpdated = new EventEmitter<void>();

  @Input() type: 'link' | 'button' = 'link';
  @Input() text = '';
  @Input() helpText = '';
  @Input() uploadType: UploadType;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(private fileUploadService: StFileUploadService) {}

  uploadError: string;

  onAddPhoto() {
    this.fileInput.nativeElement.click();
  }

  @HostListener('change', ['$event.target.files'])
  async emitFiles(event: FileList) {
    let file: File = event && event.item(0);
    this.uploadError = undefined;

    if (file) {
      this.fileUploadService.uploadFile(this.uploadType, file).subscribe({
        next: response => {
          if (this.uploadType && UploadType.PROFILE_IMAGE === this.uploadType) {
            this.profileImageUpdated.emit();
          }
        },
        error: error => {
          const statusText = error.statusText;

          if (statusText === 'Request Entity Too Large') {
            this.uploadError =
              'The file size is too large. Please upload a file with a size of up to 8 MB.';
          } else {
            this.uploadError = 'An error occurred while uploading the file.';
          }
        },
      });
    }
  }
}
