import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StIconComponent } from '../st-icon/st-icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, StIconComponent],
  selector: 'st-link',
  template: `
    <div
      class="st-link"
      [ngClass]="{
        'st-link-primary': primary,
        'st-link-secondary': secondary,
        'st-link-warning': warning
      }">
      <div
        class="st-link-area"
        (click)="onClicked()"
        [ngStyle]="{ 'font-size': textSize }"
        [ngClass]="{
          'font-light': textStyle === 'light',
          'font-medium': textStyle === 'medium',
          'font-bold': textStyle === 'bold',
          'font-book': textStyle === 'book',
          'text-decoration-line-on': textUnderline,
          'text-decoration-line-off': !textUnderline,
          inactive: !active
        }">
        <st-icon
          *ngIf="icon !== null"
          type="{{ icon }}"
          color="{{ iconColor }}"
          size="m"></st-icon>
        <span>{{ text }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./st-link.component.scss'],
})
export class StLinkComponent {
  @Output() linkClicked = new EventEmitter<void | string>();

  @Input() id = '';
  @Input() text = '';
  @Input() textSize = '';
  @Input() textStyle: 'light' | 'medium' | 'bold' | 'book' = 'book';
  @Input() textUnderline = true;
  @Input() active = true;
  @Input() primary = false;
  @Input() secondary = false;
  @Input() warning = false;
  @Input() icon: 'plus-circle' = null;
  @Input() iconColor: 'orange' = null;

  @Input() stLinkControl = new FormControl('');

  onClicked(): void {
    if (this.id && this.id.length > 0) {
      this.linkClicked.emit(this.id);
      return;
    }
    this.linkClicked.emit();
  }
}
