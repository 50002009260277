import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlLabelComponent } from '../control-label/control-label.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  standalone: true,
  imports: [CommonModule, ControlLabelComponent, SvgIconComponent],
  selector: 'st-multiple-choose',
  template: `<div
    class="st-multiple-choose"
    [ngClass]="{ 'st-multiple-choose--invalid': invalid }">
    <st-control-label
      label="{{ title }}"
      [description]="helpText"
      [required]="required"
      [invalid]="invalid" />

    <div
      *ngIf="itemsDictionary"
      class="flex flex-col lg:flex-row lg:flex-wrap gap-3 md:gap-5 flex-wrap"
      [ngClass]="{
        'flex-row': viewMode === 'row',
        'flex-col': viewMode === 'column'
      }">
      <span
        class="st-multiple-choose__item flex items-center"
        *ngFor="let item of itemsDictionary"
        (click)="markItem(item)"
        [ngClass]="{
          'st-multiple-choose__item--selected': isAlreadySelected(item)
        }">
        <span>{{ item.value }}</span>
        <svg-icon
          *ngIf="removable"
          src="assets/svg/ico-close.svg"
          class="ml-2 -mr-2"
          [svgStyle]="{ 'width.px': 18 }" />
      </span>
    </div>

    <div class="st-multiple-choose__error" *ngIf="invalid">
      {{ invalidMessage }}
    </div>
  </div> `,
  styles: [
    `
      @import 'variables';

      .st-multiple-choose {
        &__item {
          text-align: center;
          width: fit-content;

          background: $base-gray-10;
          border: 1px solid $base-gray-10;
          border-radius: 24px;
          cursor: pointer;
          padding: 10px 24px 8px;

          transition: all 0.3s ease;

          // disable text selection
          user-select: none;

          &--selected {
            background-color: $primary;
            color: white;
          }

          &:hover:not(.st-multiple-choose__item--selected) {
            background-color: $base-gray-20;
            color: $primary;
          }
        }

        &__error {
          @apply text-sm mt-2 ml-1;
          color: $alert-100;
        }
      }
    `,
  ],
})
export class MultipleChooseComponent {
  @Input() title = '';
  @Input() helpText = '';
  @Input() itemsDictionary: { value: string }[] = [];
  @Input() selectedItems: { value: string }[] = [];
  @Input() multiSelect = true;
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() invalidMessage: string = '';
  @Input() viewMode: 'row' | 'column' = 'row';
  @Input() removable: boolean = false;

  @Output() itemsUpdated = new EventEmitter<string[]>();
  @Output() itemDeleted = new EventEmitter<{ value: string }>();

  markItem(item: { value: string }) {
    if (this.removable) {
      this.itemDeleted.emit(item);
    } else {
      this.selectedItems = this.updateSelectedCollection(
        this.selectedItems,
        item
      );

      this.itemsUpdated.emit(this.selectedItems.map(item => item.value));
    }
  }

  isAlreadySelected(candidate: { value: string }): boolean {
    return (
      this.selectedItems.find(item => item.value === candidate.value) != null
    );
  }

  private updateSelectedCollection(
    currentlySelectedItems: { value: string }[],
    itemToAdd: { value: string }
  ): { value: string }[] {
    if (!currentlySelectedItems.find(item => item.value === itemToAdd.value)) {
      return this.multiSelect
        ? [...currentlySelectedItems, itemToAdd]
        : [itemToAdd];
    } else {
      return Object.assign(
        [],
        currentlySelectedItems.filter(item => item.value != itemToAdd.value)
      );
    }
  }
}
