export class UserModel {
  private readonly _expiredAt: Date;
  private readonly _authorities: string[];

  constructor(
    private _jwtToken: string,
    private _jwtRefresh: string
  ) {
    if (this._jwtToken) {
      this._authorities = JSON.parse(atob(this._jwtToken?.split('.')[1]))[
        'authorities'
      ];
      // console.log(exp);
      // this._expiredAt = new Date(exp);
      // console.log(new Date(exp));
    }
  }

  get token(): string {
    // if (!this._expiredAt && new Date() > this._expiredAt) {
    //   return null;
    // }
    return this._jwtToken;
  }

  get refreshToken(): string {
    return this._jwtRefresh;
  }

  get authorities(): string[] {
    return [...this._authorities];
  }

  get isAdmin(): boolean {
    return this.authorities.includes(Authority.ADMIN);
  }

  get isBasicStudent(): boolean {
    return this.authorities.includes(Authority.STUDENT_BASIC);
  }

  get isFullStudent(): boolean {
    return this.authorities.includes(Authority.STUDENT_FULL);
  }

  get isStudent(): boolean {
    return this.isBasicStudent || this.isFullStudent;
  }

  get isBasicTeacher(): boolean {
    return this.authorities.includes(Authority.TEACHER_BASIC);
  }

  get isFullTeacher(): boolean {
    return this.authorities.includes(Authority.TEACHER_FULL);
  }

  get isTeacher(): boolean {
    return this.isBasicTeacher || this.isFullTeacher;
  }
}

export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  BASIC = 'ROLE_BASIC',
  STUDENT_BASIC = 'ROLE_STUDENT_BASIC',
  STUDENT_FULL = 'ROLE_STUDENT_FULL',
  TEACHER_BASIC = 'ROLE_TEACHER_BASIC',
  TEACHER_FULL = 'ROLE_TEACHER_FULL',
}
