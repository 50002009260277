import { Component, Input } from '@angular/core';

@Component({
  selector: 'st-card-item',
  templateUrl: './st-card-item.component.html',
  styleUrls: ['./st-card-item.component.scss'],
})
export class StCardItemComponent {
  @Input() headerText: string;
}
