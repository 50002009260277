import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StComponentsComponent } from './st-components.component';
import { StMatchHeaderComponent } from './st-match-header/st-match-header.component';
import { StButtonComponent } from './st-button/st-button.component';
import { StCardComponent } from './st-card/st-card.component';
import { StCardItemComponent } from './st-card/st-card-item/st-card-item.component';
import { StArticleComponent } from './st-article/st-article.component';
import { InputComponent } from './input/input.component';
import { StSelectComponent } from './st-select/st-select.component';
import { StTextareaComponent } from './st-textarea/st-textarea.component';
import { StCheckboxComponent } from './st-checkbox/st-checkbox.component';
import { StLinkComponent } from './st-link/st-link.component';
import { StFileUploadComponent } from './st-file-upload/st-file-upload.component';
import { SearchComponent } from './st-search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { StDropdownMenuComponent } from './st-dropdown-menu/st-dropdown-menu.component';
import { StLoaderComponent } from './st-loader/st-loader.component';
import { StChevronButtonComponent } from './st-chevron-button/st-chevron-button.component';

import { StRadioGroupComponent } from './st-radio-group/st-radio-group.component';
import { StListComponent } from './st-list/st-list.component';
import { StIconComponent } from './st-icon/st-icon.component';
import { StFlagIconComponent } from './st-flag-icon/st-flag-icon.component';
import { StLinksEditorComponent } from './st-links-editor/st-links-editor.component';
import { MultipleChooseComponent } from './st-multiple-choose/multiple-choose.component';

import { SvgIconComponent, provideAngularSvgIcon } from 'angular-svg-icon';
import { StSingleSelectBtnComponent } from './st-single-select-btn/st-single-select-btn.component';
import { ImageComponent } from './image/image.component';

import { RadioComponent } from './radio/radio.component';

import { PasswordCheckerComponent } from './password-checker/password-checker.component';

@NgModule({
  declarations: [
    StComponentsComponent,
    StArticleComponent,
    StCardComponent,
    StCardItemComponent,

    StMatchHeaderComponent,

    StRadioGroupComponent,
    StListComponent,

    StLinksEditorComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SvgIconComponent,
    StButtonComponent,
    StSingleSelectBtnComponent,
    StChevronButtonComponent,
    StLoaderComponent,
    InputComponent,
    StIconComponent,
    StLinkComponent,
    StFileUploadComponent,
    MultipleChooseComponent,
    SearchComponent,
    StTextareaComponent,
    StSelectComponent,
    StFlagIconComponent,
    RadioComponent,
    StCheckboxComponent,
    PasswordCheckerComponent,
    StDropdownMenuComponent,
    ImageComponent,
  ],
  exports: [
    StComponentsComponent,
    StArticleComponent,
    StButtonComponent,
    StChevronButtonComponent,
    StCardComponent,
    StCardItemComponent,
    StCheckboxComponent,
    StFileUploadComponent,
    InputComponent,
    StLinkComponent,
    StMatchHeaderComponent,
    SearchComponent,
    StSelectComponent,
    StTextareaComponent,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StDropdownMenuComponent,
    StLoaderComponent,

    RadioComponent,
    StRadioGroupComponent,
    StListComponent,
    StIconComponent,
    StFlagIconComponent,
    StLinksEditorComponent,
    MultipleChooseComponent,
    SvgIconComponent,
    StSingleSelectBtnComponent,
    ImageComponent,

    PasswordCheckerComponent,
  ],
  providers: [provideAngularSvgIcon()],
})
export class StComponentsModule {}
