import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnInit,
  HostListener,
} from '@angular/core';

import { Router } from '@angular/router';

@Directive({
  selector: '[stSeoLink]',
})
export class SeoLinkDirective implements OnInit {
  @Input() stSeoLink: string = '#';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'href',
      '/' + this.stSeoLink
    );
  }

  @HostListener('click', ['$event']) onClick($event) {
    $event.preventDefault();
    this.router.navigate([this.stSeoLink]);
  }
}
