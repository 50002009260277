<div class="how-it-works">
  <div class="header font-book">How it works</div>

  <section class="how-it-works-1">
    <div class="how-it-works-both">
      <div class="how-it-works-1-left">
        <div class="hero1-in-step1">
          <img
            src="assets/img/how-it-works/howitworks1.png"
            class="img-fluid"
            alt="" />
        </div>
        <div class="text1-in-step1">
          <st-article
            headerText="Get matched and start learning!"
            articleNumber="1">
            <p class="mb-5">A lot how we learn depends on who teaches us.</p>
            <p class="mb-8">
              <strong>68% of students said</strong> that they were more
              interested in language learning if they liked their teacher.
            </p>

            <st-button
              text="Match now and start learning"
              iconActive="true"
              routerLinkPath="/match" />
          </st-article>
        </div>
      </div>
      <div class="how-it-works-1-right">
        <img
          src="assets/img/how-it-works/howitworks2.png"
          class="img-fluid float-end"
          alt="" />
      </div>
    </div>
  </section>

  <section class="how-it-works-2">
    <div class="how-it-works-both">
      <div class="how-it-works-2-left large-computer">
        <img
          src="assets/img/how-it-works/howitworks3v2.png"
          class="img-fluid"
          alt="" />
      </div>
      <div class="how-it-works-2-left small-computer mx-auto">
        <img
          src="assets/img/how-it-works/macbook.svg"
          class="img-fluid"
          alt="" />
      </div>
      <div class="how-it-works-2-right">
        <st-article headerText="Gamify your progress." articleNumber="2">
          <p class="mb-5">
            Organize all your materials in one place, no need for notes.
          </p>
          <p class="mb-5">
            Use words from past lessons and games for enjoyable practice!
          </p>
        </st-article>
      </div>
    </div>
  </section>

  <section class="how-it-works-3">
    <div class="how-it-works-both-reverse">
      <div class="how-it-works-3-right large-computer">
        <img
          src="assets/img/how-it-works/howitworks4.png"
          class="img-fluid"
          alt="" />
      </div>
      <div class="how-it-works-3-right small-computer">
        <img
          src="assets/img/how-it-works/howitworks5.png"
          class="img-fluid"
          alt="" />
      </div>
      <div class="how-it-works-3-left">
        <st-article articleNumber="3" headerText="Receive feedback">
          <p class="mb-8">
            Regular, detailed feedback from your teacher keeps you motivated and
            informed about your language learning progress.
          </p>
        </st-article>
      </div>
    </div>
  </section>
</div>
