import { Component, Input } from '@angular/core';
import { StListModel } from './st-list.model';

@Component({
  selector: 'st-list',
  templateUrl: './st-list.component.html',
  styleUrls: ['./st-list.component.scss'],
})
export class StListComponent {
  @Input() items: StListModel[] = [];
}
