<div class="why-speaktok">
  <div class="why-speaktok-header">Why Speaktok?</div>
  <div class="why-speaktok-explanation">
    <div>
      <p class="why-speaktok-reason-header">
        <img
          src="assets/img/success.svg"
          class="img-fluid"
          width="32px"
          alt="" />
        <span>Qualified native speakers & teachers</span>
      </p>
      <p class="why-speaktok-reason-content">
        All teachers undergo an extensive verification and training process to
        ensure every student receives a premium learning experience.
      </p>
    </div>
    <div>
      <p class="why-speaktok-reason-header">
        <img
          src="assets/img/success.svg"
          class="img-fluid"
          width="32px"
          alt="" />
        <span>Constant progress</span>
      </p>
      <p class="why-speaktok-reason-content">
        Everyone knows that good things come in threes. That’s why you will have
        the teacher, the platform and the feedback to carefully monitor your
        language progress.
      </p>
    </div>
    <div>
      <p class="why-speaktok-reason-header">
        <img
          src="assets/img/success.svg"
          class="img-fluid"
          width="32px"
          alt="" />
        <span>Well-organized calendar</span>
      </p>
      <p class="why-speaktok-reason-content">
        You’re the boss. Create your perfect learning schedule and choose your
        fixed hours for class.
      </p>
    </div>
    <div class="buttons">
      <st-button
        text="Match now and start learning"
        iconActive="true"
        width="296px"
        routerLinkPath="/match"></st-button>
    </div>
  </div>
</div>
