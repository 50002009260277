import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { NavigationPathsService } from '../navigation.paths.service';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { UserModel } from './user.model';
import { ProblemDetail } from '../shared/error/problem-detail';
import { ProfileModel } from './profile.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  loggedInUser = new BehaviorSubject<UserModel>(null);
  settings = new BehaviorSubject<ProfileModel>(null);

  private readonly LOGGED_IN_USER = 'user';
  private readonly LEGGED_IN_USER_PROFILE_SETTINGS = 'settings';

  private tokenExpirationTimer: any;

  constructor(
    private http: HttpClient,
    private paths: NavigationPathsService
  ) {}

  get loggedUserSettings(): ProfileModel {
    return JSON.parse(
      localStorage.getItem(this.LEGGED_IN_USER_PROFILE_SETTINGS)
    );
  }

  get loggedUserFirstName(): string {
    return this.loggedUserSettings?.fullName.split(' ')[0] ?? '';
  }

  get loggedUserLastName(): string {
    return this.loggedUserSettings?.fullName.split(' ')[1] ?? '';
  }

  get loggedUserAvatarUrl(): string {
    return this.loggedUserSettings?.avatarUrl;
  }

  login(loginForm: LoginForm): Observable<Token[]> {
    return this.http.post<Token[]>(this.paths.login(), loginForm).pipe(
      tap(tokens => {
        const userModel = new UserModel(
          tokens.find(token => TokenType.JWT_TOKEN === token.type)?.value,
          tokens.find(token => TokenType.JWT_REFRESH_TOKEN === token.type)
            ?.value
        );
        localStorage.setItem(this.LOGGED_IN_USER, JSON.stringify(userModel));
        this.loggedInUser.next(userModel);
        this.loadBasicSettings();
      }),
      catchError(this.handleError)
    );
  }

  refreshToken(): Observable<Token[]> {
    return this.http.put<Token[]>(this.paths.refreshToken(), null).pipe(
      tap(tokens => {
        const userModel = new UserModel(
          tokens.find(token => TokenType.JWT_TOKEN === token.type)?.value,
          tokens.find(token => TokenType.JWT_REFRESH_TOKEN === token.type)
            ?.value
        );
        localStorage.setItem(this.LOGGED_IN_USER, JSON.stringify(userModel));
        this.loggedInUser.next(userModel);
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    const problemDetails = error.error as ProblemDetail;
    if (problemDetails.status === 400) {
      return throwError(() => new Error(problemDetails.title));
    }
    return throwError(() => new Error('Unknown error'));
  }

  autoLogin(): void {
    const loggedInUser: {
      _jwtToken: string;
      _jwtRefresh: string;
    } = JSON.parse(localStorage.getItem(this.LOGGED_IN_USER));

    if (!loggedInUser) {
      return;
    }

    const newLoggedInUser = new UserModel(
      loggedInUser._jwtToken,
      loggedInUser._jwtRefresh
    );

    if (newLoggedInUser.token) {
      this.loggedInUser.next(newLoggedInUser);
      //todo: unlock it when exp property will be available in jwt
      //todo: add token refresh feature there too
      //this.autoLogout();
      localStorage.setItem(
        this.LOGGED_IN_USER,
        JSON.stringify(newLoggedInUser)
      );
      this.loadBasicSettings();
    }
  }

  logout(): void {
    this.loggedInUser.next(null);
    this.settings.next(null);

    localStorage.removeItem(this.LOGGED_IN_USER);
    localStorage.removeItem(this.LEGGED_IN_USER_PROFILE_SETTINGS);

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
  }

  autoLogout() {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, 180);
  }

  activateTeacher(registrationForm: RegistrationForm): Observable<void> {
    return this.http.post<void>(
      this.paths.activateTeacherAccount(),
      registrationForm
    );
  }

  signupStudent(registrationForm: RegistrationForm): Observable<void> {
    return this.http.post<void>(this.paths.signupStudent(), registrationForm);
  }

  activateStudent(activationForm: ActivationForm): Observable<void> {
    return this.http.post<void>(
      this.paths.activateStudentAccount(),
      activationForm
    );
  }

  sendChangePasswordLink(
    changePasswordRequest: ChangePasswordRequestForm
  ): Observable<void> {
    return this.http.post<void>(
      this.paths.changePasswordRequest(),
      changePasswordRequest
    );
  }

  changePassword(setNewPasswordForm: SetNewPasswordForm): Observable<void> {
    return this.http.post<void>(
      this.paths.changePassword(),
      setNewPasswordForm
    );
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(this.LOGGED_IN_USER);
  }

  loadBasicSettings() {
    this.http
      .get<ProfileModel>(this.paths.meProfileSettings())
      .subscribe(profileSettings => {
        localStorage.setItem(
          this.LEGGED_IN_USER_PROFILE_SETTINGS,
          JSON.stringify(profileSettings)
        );

        this.settings.next(profileSettings);
      });
  }
}

export interface LoginForm {
  username: string;
  password: string;
  activationToken?: string;
  timeZoneId?: string;
}

export interface RegistrationForm {
  username: string;
  password: string;
  passwordConfirmation?: string;
  choseTeacherProfileId?: string;
  activationToken?: string;
}

export interface ActivationForm {
  username: string;
  activationToken: string;
}

export interface ChangePasswordRequestForm {
  email: string;
}

export interface SetNewPasswordForm {
  newPassword: string;
  changePasswordToken: string;
}

export enum TokenType {
  JWT_TOKEN = 'JWT_TOKEN',
  JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN',
}

export class Token {
  type: TokenType;
  value: string;
}
