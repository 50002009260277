import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { optionModel } from './st-single-select-btn.model';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-single-select-btn',
  template: `
    <div class="single-select-btn mx-auto inline-flex justify-center">
      <div
        *ngFor="let option of options"
        class="single-select-btn__item"
        [ngClass]="{
          'single-select-btn__item--active': currentValue === option.value
        }"
        (click)="onClick(option)">
        {{ option.name }}
      </div>
    </div>
  `,

  styles: [
    `
      @import 'variables';
      $radius: 30px;
      .single-select-btn {
        background: $base-gray-10;
        border-radius: $radius;

        &__item {
          @apply pt-1 px-4;
          border-bottom: 3px;
          border-radius: $radius;
          cursor: pointer;
          &--active {
            background: $primary;
            color: $white;
          }
        }
      }
    `,
  ],
})
export class StSingleSelectBtnComponent implements OnInit {
  @Input() options: optionModel[] = [];
  @Input() current: string | number | boolean = '';

  @Output() selected = new EventEmitter<optionModel>();

  currentValue: string | number | boolean = '';

  ngOnInit(): void {
    this.currentValue = this.current;
  }

  onClick(option: optionModel): void {
    this.currentValue = option.value;
    this.selected.emit(option);
  }
}
