import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioModel } from '../radio/radio.model';

@Component({
  selector: 'st-radio-group',
  templateUrl: './st-radio-group.component.html',
  styleUrls: ['./st-radio-group.component.scss'],
})
export class StRadioGroupComponent {
  @Input() models: RadioModel[] = [];
  @Output() radioButtonClicked = new EventEmitter<RadioModel>();

  updateModel(selected: RadioModel) {
    this.models
      .filter(model => model.value != selected.value)
      .forEach(model => (model.selected = false));
    this.models
      .filter(model => model.value === selected.value)
      .forEach(model => (model.selected = selected.selected));
    this.radioButtonClicked.emit(selected);
  }
}
