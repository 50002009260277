import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'st-root',
  template: `
    <st-auth-wall></st-auth-wall>
    <router-outlet></router-outlet>
  `,
  styles: [``],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.autoLogin();
  }
}
