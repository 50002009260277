import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthWallService } from './auth-wall/auth-wall.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private authWallService: AuthWallService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.loggedInUser.pipe(
      take(1),
      map(user => {
        if (!!user) {
          return true;
        }
        this.authWallService.activated.next({
          loginMode: true,
          authWallActive: true,
        });
        return this.router.createUrlTree(['/']);
      })
    );
  }
}
