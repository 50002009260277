import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-button',
  template: `
    <button
      role="button"
      type="button"
      [ngStyle]="{ width: width }"
      (click)="onButtonClick()"
      [disabled]="disabled"
      [class]="'px-5 st-button ' + style + ' ' + class + ' ' + sizeClass"
      [ngClass]="{
        'st-button--disabled': disabled,
        'st-button--block': block,
        'st-button--no-wrap': noWrap
      }">
      {{ text }}
      <ng-content />
      <i *ngIf="iconActive" class="st-right-arrow"></i>
    </button>
  `,
  styles: [
    `
      @import 'variables';

      .st-button {
        font-size: 16px;
        font-weight: 400;

        padding-top: 9px;
        padding-bottom: 7px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        transition: all 0.3s ease;

        min-height: 42px;

        & > i {
          content: url('./assets/icons/right_icon.svg');
        }

        // variants (style)
        &.st-orange {
          background: #ffbc46;
          border: 1px solid #ffbc46;
          box-shadow: 1px 2px 12px rgba(90, 89, 82, 0.15);
          border-radius: 28px;
          color: $primary;
          &:hover {
            border: 1px solid $primary;
          }
        }

        &.st-orange-grey {
          background: $base-gray-10;
          border: 2px solid #ffbc46;
          box-shadow: 1px 2px 12px rgba(90, 89, 82, 0.15);
          border-radius: 28px;
          color: $primary;
          &:hover {
            background: #ffbc46;
          }
        }

        &.st-grey {
          background: $base-gray-10;
          border: none;
          box-shadow: 1px 2px 12px rgba(90, 89, 82, 0.15);
          border-radius: 24px;
          &:hover {
            background: $primary;
            border: none;
            color: $white;
          }
        }

        &.st-green {
          background: $success-100;
          border: none;
          box-shadow: 1px 2px 12px rgba(90, 89, 82, 0.15);
          border-radius: 24px;
          color: $white;

          &:hover {
            background: darken($success-100, 5%);
          }
        }

        &.st-red {
          background: $alert-100;
          border: none;
          box-shadow: 1px 2px 12px rgba(90, 89, 82, 0.15);
          border-radius: 24px;
          color: $white;
          &:hover {
            background: darken($alert-100, 5%);
          }
        }

        &.link {
          @apply font-book;
          background: none;
          border: none;
          color: $primary;
          &:hover {
            text-decoration: underline;
          }
        }

        // modifiers
        &--block {
          width: 100%;
        }

        &--disabled {
          opacity: 0.7;
          pointer-events: none;
        }

        &--no-wrap {
          white-space: nowrap;
        }

        // sizes
        &--s-sm {
          font-size: 14px;
          padding-top: 7px;
          padding-bottom: 5px;
          min-height: 36px;
        }

        &--s-lg {
          font-size: 18px;
          padding-top: 11px;
          padding-bottom: 9px;
          min-height: 48px;
        }
      }
    `,
  ],
})
export class StButtonComponent {
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() text: string = '';
  @Input() iconActive: boolean = false;
  @Input() routerLinkPath: string = '';
  @Input() width: string = '';
  @Input() style:
    | 'st-orange'
    | 'st-grey'
    | 'st-orange-grey'
    | 'st-green'
    | 'link'
    | 'st-red' = 'st-orange';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() noWrap: boolean = false;
  @Input() class: string = '';

  constructor(private router: Router) {}

  get sizeClass(): string {
    return `st-button--s-${this.size}`;
  }

  onButtonClick() {
    if (this.routerLinkPath && this.routerLinkPath.length) {
      this.router.navigate([this.routerLinkPath]);
    } else {
      this.buttonClicked.emit();
    }
  }
}
