import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-chevron-button',
  template: `
    <div
      class="st-chevron-button"
      [ngClass]="{ 'st-chevron-button--disabled': disabled }">
      <i
        *ngIf="type === 'left'"
        class="left-chevron"
        (click)="onClick($event)"></i>
      <i
        *ngIf="type === 'right'"
        class="right-chevron"
        (click)="onClick($event)"></i>
    </div>
  `,
  styles: [
    `
      .st-chevron-button {
        width: 32px;
        height: 32px;

        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .left-chevron {
        content: url('/assets/components/icons/chevron-left.svg');
        cursor: pointer;
      }

      .right-chevron {
        content: url('/assets/components/icons/chevron-right.svg');
        cursor: pointer;
      }
    `,
  ],
})
export class StChevronButtonComponent {
  @Output() buttonClicked = new EventEmitter<void>();

  @Input() type: 'left' | 'right' = 'right';
  @Input() disabled: boolean = false;

  @Input() stChevronButtonControl = new FormControl('');

  onClick(event: MouseEvent) {
    this.buttonClicked.emit();
  }
}
