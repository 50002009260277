import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  selector: 'st-checkbox',
  template: `
    <div
      class="st-checkbox flex items-center"
      [ngClass]="{ 'st-checkbox--readonly': readonly }"
      (click)="toggle()">
      <div
        class="st-checkbox__checkbox "
        [ngClass]="{ 'st-checkbox__checkbox--checked': selected }"></div>

      <div
        class="st-checkbox__label ml-3"
        [ngClass]="{ label: !readonly, 'label-readonly': readonly }"
        *ngIf="label">
        {{ label }}
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./st-checkbox.component.scss'],
})
export class StCheckboxComponent implements OnInit, OnDestroy {
  private changeStateSub: Subscription;

  @Input() label = '';
  @Input() selected = false;
  @Input() readonly = false;

  @Input() checkboxControl = new FormControl('');
  @Output() changed = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.readonly) {
      this.changeStateSub = this.checkboxControl.valueChanges.subscribe(
        value => (this.selected = !!value)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.readonly && this.changeStateSub) {
      this.changeStateSub.unsubscribe();
    }
  }

  toggle(): void {
    if (this.readonly) {
      return;
    }

    this.selected = !this.selected;
    this.changed.emit(this.selected);
  }
}
