import { Injectable } from '@angular/core';
import { NavigationPathsService } from '../../navigation.paths.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StFileUploadService {
  constructor(
    private navigationPaths: NavigationPathsService,
    private http: HttpClient
  ) {}

  uploadFile(
    uploadType: UploadType,
    file: File
  ): Observable<HttpResponse<void>> {
    const formData = new FormData();
    formData.append('file', file);
    let headers = new HttpHeaders({ 'ST-UPLOAD-TYPE': uploadType });
    return this.http.post<void>(this.navigationPaths.files(), formData, {
      observe: 'response',
      headers: headers,
    });
  }
}

export enum UploadType {
  PROFILE_IMAGE = 'PROFILE_IMAGE',
}
