import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RadioModel } from './radio.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'st-radio',
  imports: [CommonModule],
  template: `
    <div
      class="st-radio flex gap-2 relative"
      [ngClass]="[
        isSelected ? 'st-radio--selected' : 'st-radio--unselected',
        class
      ]"
      (click)="toggle()">
      <div class="st-radio__label" [ngClass]="labelClass">
        {{ label }}
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      @import 'variables';

      .st-radio {
        cursor: pointer;

        &::before {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          min-width: 18px;
          border-radius: 50%;
          border: 1px solid $base-gray-30;

          transition: all 0.2s ease-in-out;
        }

        &:hover::before {
          border-color: $primary;
        }

        &--selected::before {
          border-color: $primary;
          border-width: 5px;
        }

        &__label {
        }
      }
    `,
  ],
})
export class RadioComponent {
  @Input() inputControl = new FormControl('');
  @Input() label: string;
  @Input() value: string = '';
  @Input() class: string = '';
  @Input() labelClass: string = '';

  @Output() selected = new EventEmitter<any>();
  @Output() clicked = new EventEmitter<any>();

  get isSelected(): boolean {
    return this.inputControl.value === this.value;
  }

  toggle() {
    this.inputControl.setValue(this.value);
    this.selected.emit(this.value);
    this.clicked.emit();
  }
}
