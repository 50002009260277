import { Component, OnInit } from '@angular/core';
import { BillingPlanModel } from './model/billing-plan.model';
import { BillingPlansService } from './billing-plans.service';

@Component({
  selector: 'st-billing-plans',
  template: `
    <div *ngIf="showBillingPlans" class="billing-plans mb-20 px-5 lg:px-10">
      <h3
        class="text-5xl xs:text-4xl mt-5 mb-16 xs:mb-6 block text-center font-book">
        Billing plans
      </h3>

      <st-loader *ngIf="loading" size="sm" class="flex justify-center m-10" />

      <st-single-select-btn
        class="flex justify-center mb-8 lg:mb-16"
        [options]="periodOptions"
        [current]="selectedPeriod"
        (selected)="onSelected($event)" />

      <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
        <div
          class="billing-plans__item py-10 px-5 sm:px-10 lg:px-5 xl:px-10 flex lg:flex-col xs:flex-col"
          *ngFor="let plan of getPlansBySelectedPeriod">
          <div class="sm:w-5/12 md:w-4/12 lg:w-full">
            <div class="mt-6">
              {{ frequencyPerWeek(plan) }}
            </div>
            <div class="text-5xl font-medium">
              {{ plan.name }}
            </div>
            <div class="billing-plans__item-price mt-5">
              {{ pricePerLesson(plan) }} <span>/60min.</span>
            </div>
            <div class="mb-5">{{ pricePerMonth(plan) }} per month</div>
          </div>
          <div class="flex-grow flex flex-col">
            <div *ngIf="plan.description.length" class="font-medium mb-5">
              For whom:
            </div>

            <ul *ngIf="plan.description.length" class="list-with-stars">
              <li *ngFor="let d of plan.description">{{ d }}</li>
            </ul>

            <div class="flex-grow mt-10 flex flex-col justify-end">
              <st-button
                text="Match me with a teacher"
                routerLink="/match"
                [queryParams]="{ plan: plan.id }"
                [iconActive]="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!showBillingPlans"
      class="billing-plans mb-20 px-5 lg:px-10"></div>
  `,
  styles: [
    `
      @import 'variables';
      .billing-plans {
        &__item {
          color: $base-space-100;
          background-color: $base-gray-10;
          border-radius: 48px;

          &-price {
            @apply font-light;
            font-size: 72px;
            line-height: 72px;
            span {
              @apply font-light -ml-4;
              font-size: 16px;
            }
          }
        }
      }
    `,
  ],
})
export class BillingPlansComponent implements OnInit {
  constructor(private billingPlansService: BillingPlansService) {}

  private _showBillingPlans = true;

  billingPlans: BillingPlanModel[] = [];
  loading: boolean = true;
  selectedPeriod: number = 1;

  ngOnInit(): void {
    this.billingPlansService.getBillingPlans().subscribe({
      next: plans => {
        // console.log('plans', plans);
        this.billingPlans = plans.filter(plan => plan.type !== 'TRIAL');
        this._showBillingPlans =
          this.billingPlans && this.billingPlans.length > 0;
      },
      error: err => {
        console.log('error loading billing plans ', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  get periodOptions(): object[] {
    const periods = Array.from(
      new Set(this.billingPlans.map(plan => plan.frequency))
    );
    periods.sort((a, b) => a - b);

    return periods.map(period => ({
      name: period === 1 ? 'monthly' : `${period} months`,
      value: period,
    }));
  }

  get getPlansBySelectedPeriod(): BillingPlanModel[] {
    const filteredPlans = this.billingPlans.filter(
      plan => plan.frequency === this.selectedPeriod
    );
    filteredPlans.sort(
      (a, b) => a.lessonsInBillingPeriod - b.lessonsInBillingPeriod
    );
    return filteredPlans;
  }

  get showBillingPlans(): boolean {
    return this._showBillingPlans;
  }

  frequencyPerWeek(plan: BillingPlanModel): string {
    const f = plan.lessonsInBillingPeriod / (plan.frequency * 4);
    return `${f === 1 ? 'once a' : f >= 3 ? '3+ x' : f + ' x '} week`;
  }

  pricePerLesson(plan: BillingPlanModel): string {
    return `$${(plan.price / plan.lessonsInBillingPeriod).toFixed(0)}`;
  }

  pricePerMonth(plan: BillingPlanModel): string {
    return `$${(plan.price / plan.frequency).toFixed(0)}`;
  }

  onSelected(selected): void {
    this.selectedPeriod = selected.value;
  }
}
