import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthWallService } from './auth-wall.service';
import { AuthService, RegistrationForm } from '../auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatchService } from '../../match/match.service';

@Component({
  selector: 'st-auth-wall',
  templateUrl: './auth-wall.component.html',
  styleUrls: ['./auth-wall.component.scss'],
})
export class AuthWallComponent implements OnInit, OnDestroy {
  wallType:
    | 'confirmation'
    | 'registration'
    | 'login'
    | 'changePass'
    | 'changePassConfirmation'
    | 'setNewPass';
  confirmationWall = false;
  registrationWall = false;
  loginWall = false;
  changePasswordWall = false;
  setNewPasswordWall = false;
  changePasswordConfirmationWall = false;

  displayMode: 'flex' | 'none' = 'none';
  linkFontSizeValue = '13px';

  loginError = false;
  loginErrorMessage = '';

  usernameControl = new FormControl('', [Validators.email]);
  passwordControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);

  private activationToken: string;
  private userEmail: string;
  private changePasswordToken: string;
  private teacher = false;
  private loginErrorTimer: any;

  constructor(
    private authWallService: AuthWallService,
    private authService: AuthService,
    private router: Router,
    private matchService: MatchService
  ) {}

  get isActive(): boolean {
    return this.displayMode === 'flex';
  }

  ngOnInit(): void {
    this.authWallService.activated.subscribe(activation => {
      this.activationToken = activation.activationToken;
      this.userEmail = activation.email;
      this.changePasswordToken = activation.changePasswordToken;

      if (activation.registrationMode) {
        this.wallType = 'registration';
      } else if (activation.setNewPasswordMode) {
        this.wallType = 'setNewPass';
      } else if (activation.loginMode) {
        this.wallType = 'login';
      }
      this.teacher = activation.teacher;

      activation.authWallActive ? (this.displayMode = 'flex') : 'none';

      if (this.userEmail) {
        this.usernameControl.setValue(this.userEmail);
      }
    });
  }

  ngOnDestroy(): void {
    this.resetAuthWall();
    this.resetFormToClearLogin();
  }

  @HostListener('window.resize', ['event'])
  linkTextSize(event?) {
    if (window.outerWidth <= 360) {
      this.linkFontSizeValue = '2.5rem';
    } else {
      this.linkFontSizeValue = '13px';
    }
  }

  loginWithActivation() {
    if (!this.teacher && this.activationToken) {
      this.authService
        .activateStudent({
          username: this.usernameControl.value,
          activationToken: this.activationToken,
        })
        .subscribe(next => this.login());
    } else {
      this.login();
    }
  }

  signup() {
    let signupForm: RegistrationForm = {
      username: this.usernameControl.value,
      password: this.passwordControl.value,
      activationToken: this.activationToken,
    };

    if (this.teacher) {
      this.authService
        .activateTeacher(signupForm)
        .subscribe(next => this.onChangeToLoginMode());
    } else {
      if (this.activationToken) {
        this.authService
          .activateStudent({
            username: signupForm.username,
            activationToken: signupForm.activationToken,
          })
          .subscribe(next => this.onChangeToLoginMode());
      } else {
        signupForm.choseTeacherProfileId =
          this.matchService.getSelectedTeacher()?.id;
        this.authService.signupStudent(signupForm).subscribe(next => {
          this.onChangeToConfirmationMessageWall();
        });
      }
    }
  }

  onCloseClick() {
    // scroll to top
    window.scrollTo(0, 0);

    this.displayMode = 'none';
    this.resetAuthWall();
  }

  onChangeToLoginMode() {
    this.resetAuthWall();
    this.wallType = 'login';
  }

  onChangeToConfirmationMessageWall() {
    this.resetAuthWall();
    this.wallType = 'confirmation';
    this.activationToken = null;
  }

  private showErrorMessage(errorMessage: string): void {
    this.loginErrorMessage = errorMessage;
    this.loginError = true;
    this.loginErrorTimer = setTimeout(() => {
      this.loginError = false;
      this.loginErrorMessage = '';
    }, 10000);
  }

  private resetFormToClearLogin() {
    this.usernameControl.reset();
    this.passwordControl.reset();
  }

  onSendResetLink() {
    this.authService
      .sendChangePasswordLink({
        email: this.usernameControl.value,
      })
      .subscribe(next => {
        this.usernameControl.reset();
        this.resetAuthWall();
        this.wallType = 'changePassConfirmation';
      });
  }

  onChangePassword() {
    this.authService
      .changePassword({
        newPassword: this.passwordControl.value,
        changePasswordToken: this.changePasswordToken,
      })
      .subscribe(next => {
        this.passwordControl.reset();
        this.resetAuthWall();
        this.wallType = 'login';
      });
  }

  onShowChangePasswordWall() {
    this.resetAuthWall();
    this.wallType = 'changePass';
  }

  onMatchTeacher() {
    this.onCloseClick();
    this.router.navigate(['/match']);
  }

  onRegisterUserWithBoughtPlan() {
    this.resetAuthWall();

    this.wallType = 'registration';
    this.teacher = false;
  }

  private resetAuthWall() {
    this.userEmail = undefined;
    this.wallType = 'login';
    this.resetFormToClearLogin();
  }

  private login() {
    this.authService
      .login({
        username: this.usernameControl.value,
        password: this.passwordControl.value,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .subscribe(
        next => {
          this.onCloseClick();
          this.router.navigate(['dashboard']);
        },
        error => {
          if (error.message === 'empty.input.data') {
            this.showErrorMessage(
              'Both email and password are required for login'
            );
            this.resetFormToClearLogin();
          } else if (error.message === 'user.not.found') {
            this.showErrorMessage('User with given email not found');
            this.resetFormToClearLogin();
          } else if (error.message === 'user.is.not.active') {
            this.showErrorMessage('Upps! Choose you billing plan before');
            this.resetFormToClearLogin();
          } else if (error.message === 'password.not.match') {
            this.showErrorMessage('Given password is incorrect');
          } else {
            this.showErrorMessage('Something went wrong, try again please');
          }
        },
        () => clearTimeout(this.loginErrorTimer)
      );
  }
}
