import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'st-icon',
  templateUrl: './st-icon.component.html',
  styleUrls: ['./st-icon.component.scss'],
})
export class StIconComponent {
  @Input() type:
    | 'none'
    | 'circle'
    | 'delete'
    | 'chevron-down'
    | 'search'
    | 'plus-circle'
    | 'settings'
    | 'close'
    | 'youtube' = 'none';

  @Input() color: 'primary' | 'orange' | 'grey' = 'primary';

  @Input() size: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' = 'l';

  @Input() mode: 'icon' | 'button' = 'icon';
}
