import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'st-avatar',
  standalone: true,
  imports: [CommonModule],
  template: `
    <img
      *ngIf="src"
      [src]="src"
      [alt]="alt"
      class=" rounded-full"
      [ngClass]="sizeClass" />
    <div
      *ngIf="!src && alt"
      class=" rounded-full flex items-center justify-center uppercase st-avatar__initials"
      [ngClass]="sizeClass">
      <span class="pt-1">{{ initials }}</span>
    </div>
  `,
  styles: [
    `
      @import 'variables';
      .st-avatar {
        &__initials {
          background-color: $base-space-medium;
          color: #fff;
        }
      }
    `,
  ],
})
export class AvatarComponent {
  @Input() src: string;
  @Input() alt: string;
  @Input() size: 'xs' | 'sm' | 'xmd' | 'md' | 'lg' = 'md';

  get initials(): string {
    return this.alt
      .split(' ')
      .map(word => word[0])
      .slice(0, 2)
      .join('');
  }

  get sizeClass(): string {
    return {
      xs: 'w-4 h-4 text-xxs',
      sm: 'w-6 h-6 text-xs',
      xmd: 'w-9 h-9 text-sm',
      md: 'w-12 h-12',
      lg: 'w-16 h-16',
    }[this.size];
  }
}
