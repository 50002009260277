import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'st-control-label',
  standalone: true,
  imports: [CommonModule],
  template: `
    <h4
      class="control-label header-h3"
      [ngClass]="{ 'control-label--invalid': invalid }">
      {{ label }}<span *ngIf="required" class="text-gray-400">*</span>
      <span *ngIf="required && invalid && !invalidMessage" class="text-sm">
        (Required)</span
      >
      <span *ngIf="invalid && invalidMessage" class="text-sm">
        ({{ invalidMessage }})</span
      >
    </h4>
    <div *ngIf="description" class="-mt-5 mb-7">
      {{ description }}
    </div>
  `,
  styles: [
    `
      @import 'variables.scss';

      .control-label {
        &--invalid {
          color: $alert-100;
        }
      }
    `,
  ],
})
export class ControlLabelComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() invalidMessage: string;
}
