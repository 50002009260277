import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PasswordCheckerService } from './password-checker.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { StCheckboxComponent } from '../st-checkbox/st-checkbox.component';

@Component({
  standalone: true,
  imports: [CommonModule, StCheckboxComponent],
  selector: 'st-password-checker',
  template: `
    <div class="password-checker mb-6">
      <st-checkbox
        label="At least 8 characters"
        [readonly]="true"
        [checkboxControl]="minLengthFc" />

      <st-checkbox
        label="Contains a number"
        [readonly]="true"
        [checkboxControl]="containsANumberFc" />

      <st-checkbox
        label="A mixture of both uppercase and lowercase letters"
        [readonly]="true"
        [checkboxControl]="upperLowerLettersFc" />
    </div>
  `,
  styles: [``],
})
export class PasswordCheckerComponent implements OnInit, OnDestroy {
  private _containsANumberRegexp = new RegExp(/\d+/, 'i');
  private _containsLowerCase = new RegExp(/[a-z]+/, 'i');
  private _containsUpperCase = new RegExp(/[A-Z]+/, 'i');

  private _passwordValueUpdated: Subscription;

  @Input() minLengthFc = new UntypedFormControl('');
  @Input() containsANumberFc = new UntypedFormControl('');
  @Input() upperLowerLettersFc = new UntypedFormControl('');
  @Input() password: string = '';

  constructor(private passwordCheckerService: PasswordCheckerService) {}

  setFlags(value: string): void {
    if (!value) {
      this.minLengthFc.setValue(false);
      this.containsANumberFc.setValue(false);
      this.upperLowerLettersFc.setValue(false);
      return;
    }

    this.minLengthFc.setValue(value.length >= 8);

    this.containsANumberFc.setValue(this._containsANumberRegexp.test(value));

    this.upperLowerLettersFc.setValue(
      this._containsLowerCase.test(value) && this._containsUpperCase.test(value)
    );

    this.passwordCheckerService.passwordValueValidated.next(
      this.minLengthFc.value &&
        this.containsANumberFc.value &&
        this.upperLowerLettersFc.value
    );
  }

  ngOnInit(): void {
    this._passwordValueUpdated =
      this.passwordCheckerService.passwordValueUpdated.subscribe(value => {
        this.setFlags(value);
      });
  }

  ngOnChanges(changes: any) {
    this.setFlags(changes.password.currentValue);
  }

  ngOnDestroy(): void {
    if (this._passwordValueUpdated) {
      this._passwordValueUpdated.unsubscribe();
    }
  }
}
