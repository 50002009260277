import {
  Component,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  HostListener,
} from '@angular/core';
import { StSelectOptionModel } from './st-select-option.model';
import { CommonModule } from '@angular/common';
import { StFlagIconComponent } from '../st-flag-icon/st-flag-icon.component';
import { StIconComponent } from './../st-icon/st-icon.component';
import { InputComponent } from '../input/input.component';

@Component({
  standalone: true,
  imports: [CommonModule, StFlagIconComponent, StIconComponent, InputComponent],
  selector: 'st-select',
  template: `
    <div class="st-select relative" [class.st-select--active]="active">
      <div class="label" *ngIf="label">{{ label }}</div>

      <div
        class="st-select__container flex items-center cursor-pointer"
        (click)="toggleOptionsVisibility()">
        <div class="flex-grow flex items-center w-full">
          <div *ngIf="icon && selected && selected.icon" class="ml-2">
            <st-flag-icon [type]="selected?.icon" />
          </div>

          <div
            class="ml-2 pt-[2px] pr-2 flex flex-grow st-select__selected-item">
            <span *ngIf="isSelected">
              {{ selected?.name ?? selected?.value }}
            </span>

            <span *ngIf="!isSelected" class="st-select__placeholder">
              {{ placeholder }}
            </span>
          </div>
        </div>

        <div class="flex items-center">
          <st-icon
            *ngIf="(chevronDown && !isSelected) || !selectionBlocker"
            type="chevron-down"
            size="m" />

          <st-icon
            *ngIf="isSelected && selectionBlocker"
            type="delete"
            color="grey"
            mode="button"
            size="m"
            (click)="onClear()" />
        </div>
      </div>

      <div class="st-select__options mt-2">
        <div *ngIf="isSearchVisible" class="p-2 -mb-4">
          <st-input placeholder="Search" (inputText)="onSearch($event)" />
        </div>
        <div class="st-select__options-items">
          <div
            class="st-select__options-item flex items-center "
            [ngClass]="{ 'st-select__options-item--selected': option.selected }"
            *ngFor="let option of selectOptions"
            (click)="onSelected(option)">
            <st-flag-icon *ngIf="icon" [type]="option?.icon" />

            <span class="flex-grow mx-3 text-base my-2">
              {{ option.name ? option.name : option.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./st-select.component.scss'],
})
export class StSelectComponent {
  constructor(private elementRef: ElementRef) {}

  _options: StSelectOptionModel[] = [];
  selectOptions: StSelectOptionModel[] = [];

  active = false;

  @Input() icon = true;
  @Input() chevronDown = true;

  // auto select first option if only one option is available
  @Input() autoSelect = false;
  @Input() label = '';
  @Input() selected: StSelectOptionModel;
  @Input() placeholder = '';
  @Input() selectionBlocker = false;

  @Output() optionSelected = new EventEmitter<StSelectOptionModel>();
  @Output() selectionCleared = new EventEmitter<StSelectOptionModel>();

  // @todo - powoduje problemy z odświeżaniem widoku - sprawdzić
  @Input() set options(values: StSelectOptionModel[]) {
    if (
      !(this._options && this._options.length) ||
      this._options.length !== values.length
    ) {
      this._options = values;
      this.setOptions();
    }

    if (this.autoSelect && this._options && this._options.length === 1) {
      this.selected = this._options[0];
      this.optionSelected.emit(this._options[0]);
      this.selectionBlocker = true;
      this.chevronDown = false;
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);

    if (!clickedInside) {
      this.active = false;
    }
  }

  get isSelected(): boolean {
    return this.selected && Object.keys(this.selected).length > 0;
  }

  get isSearchVisible(): boolean {
    return this._options && this._options.length > 10;
  }

  setOptions(options = []) {
    this.selectOptions = options.length
      ? options
      : this._options
      ? this._options.map(option => {
          const selectedValue = this.selected?.value ?? this.selected?.name;
          const optionValue = option?.value ?? option?.name;

          return { ...option, selected: selectedValue === optionValue };
        })
      : [];
  }

  onSelected(choseOption: StSelectOptionModel) {
    this.selected = choseOption;
    this.active = !this.active;
    this.optionSelected.emit(choseOption);
  }

  toggleOptionsVisibility(): void {
    if (
      this.selected &&
      Object.keys(this.selected).length &&
      this.selectionBlocker
    ) {
      return;
    }
    this.active = !this.active;
  }

  onClear() {
    this.selectionCleared.emit(this.selected);
    this.selected = undefined;
    this.active = !this.active;
    this.optionSelected.emit({});
  }

  onClickedOutside() {
    this.active = false;
  }

  onSearch(value) {
    console.log('search', value);

    // if (!value || !value.length) {
    //   this.setOptions();
    //   return;
    // }

    const options = this._options.filter(option => {
      const opt = (option?.name ?? option?.value ?? '').toString();

      return opt.toLowerCase().includes(value.toLowerCase());
    });

    this.setOptions(options);
  }
}
