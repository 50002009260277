import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatchedTeacherModel } from './results/model/matched-teacher-model';
import { BillingPlanModel } from '../billing-plans/billing-plan/model/billing-plan.model';

@Injectable({ providedIn: 'root' })
export class MatchService {
  matchingStepChanged = new Subject<number>();

  public saveSelectedTeacher(teacher: MatchedTeacherModel) {
    localStorage.setItem('choseTeacher', JSON.stringify(teacher));
  }

  public getSelectedTeacher(): MatchedTeacherModel {
    return JSON.parse(localStorage.getItem('choseTeacher'));
  }

  public saveChoseBillingPlan(billingPlan: BillingPlanModel): void {
    localStorage.setItem('choseBillingPlan', JSON.stringify(billingPlan));
  }

  public getChoseBillingPlan(): BillingPlanModel {
    return JSON.parse(localStorage.getItem('choseBillingPlan'));
  }
}
